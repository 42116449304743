export const sortByOrdemPregao = (processos) => {
  return processos.sort((a, b) => {
    if (a.informacoesAdicionais.OrdemPregao < b.informacoesAdicionais.OrdemPregao)
      return -1;
    if (a.informacoesAdicionais.OrdemPregao > b.informacoesAdicionais.OrdemPregao)
      return 1;
    return 0;
  });
}

export const sortBySequenciaPreferencia = (processos) => {
  return processos.sort((a, b) => {
	  if (a.informacaoProcessoSalaSessao && a.informacaoProcessoSalaSessao.sequenciaPreferencia && b.informacaoProcessoSalaSessao && b.informacaoProcessoSalaSessao.sequenciaPreferencia) {
		  if (a.informacaoProcessoSalaSessao.sequenciaPreferencia < b.informacaoProcessoSalaSessao.sequenciaPreferencia)
		      return -1;
		  else
		      return 1;
	  }
	  return 0;
  });
}

export const sortPecasById = (pecas) => {
  return pecas.sort((a, b) => {
  
    let dataA = new Date(a.data.ano,a.data.mes-1,a.data.dia).getTime();
    let dataB = new Date(b.data.ano,b.data.mes-1,b.data.dia).getTime();
    if (dataA < dataB)
      return 1;
    if (dataA > dataB)
      return -1;
    return 0;
  });
}

export const getProcessoApregoado = (processos, codigoProcesso) => {
  let processo = processos.filter((e) => {
    return e.processo.numeroCompleto === codigoProcesso
  })[0];
  return processo;
}

export const removerProcessoApregoado = (processos, codigoProcesso) => {
  return processos.filter((e) => {
    return e.processo.numeroCompleto !== codigoProcesso || e.resultadoJulgamento.descricao !== "Não julgado"
  });
}

export const getProcessosRelator = (processos, siglaRelagor) => {
  return processos.filter((e) => { return e.processo.relator.sigla === siglaRelagor });
}

export const processosJulgados = (processos) => {
  return processos.filter((e) => { 
	  //return e.resultadoJulgamento.descricao !== "Não julgado"
	  return true;
		  });
}

export const processosPendentesDeJulgamento = (processos) => {
  return processos.filter((e) => {  
	  //return e.resultadoJulgamento.descricao === "Não julgado" && isAdvogadoPresente(e)
	  return true;
	  });
}

/*const isAdvogadoPresente = (p) => {
  var retorno = false;
  if(!p.pedidoSustentacaoOral){
    return false;
  }
  if(!p.pedidoSustentacaoOral.pedidosDetalhados){
    return false;
  }

  p.pedidoSustentacaoOral.pedidosDetalhados.forEach(pedido => {
    var advogadosPresentes = pedido.advogados.filter(a => {
      return a.presente === 'S';
    });
    if(advogadosPresentes.length > 0){
      retorno = true;
    }
  });
  return retorno;
}*/

export const formataDataEHora = (valor) => {
  return formataData(valor) + ' ' + formataHora(valor);
}

export const formataData = (valor) => {
  if (valor !== null) {
    return valor.slice(0, 10).replace('-', '/').replace('-', '/');
  } else {
    return '';
  }
}

export const getPrimeiraSessao = (valor) =>{
  var valorNovo =  valor.replace(new RegExp('----.*'),"");
  return valorNovo;
}

export const formataHora = (valor) => {
  if (valor !== null) {
    return valor.slice(11, 16).replace('-', ':');
  } else {
    return '';
  }
}
