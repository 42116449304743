import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { Icon, Grid } from '@material-ui/core/';

const SustentacaoOral = (props) => {
    return (
      <span>
        {props.pedidosDetalhados!=null && props.pedidosDetalhados.length > 0 && props.pedidosDetalhados.map((item, index) => {
          return (<Grid container key={index} >
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold" }} id={"idParte" + props.indexPrincipal + index} >
                {item.denominacaoDaParte}: <span style={{ color: 'black', fontWeight: "normal" }}> {item.nomeDaParte && item.nomeDaParte.trim().slice(0, 120).toUpperCase() + (item.nomeDaParte.trim().length > 120 ? '...' : '')}</span>
              </Typography>
            </Grid>
            <Grid id={"idAdvogado" + props.indexPrincipal + index} item sm={11}>
              <Typography style={{ color: "black" }}>
                <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon style={item.advogados[0].presente === 'S' ? { color: "green", fontSize:17} : item.advogados[0].presente === 'NA' ? { color: "black", fontSize:17 } : { color: "rgb(183, 28, 28)", fontSize:17}}>record_voice_over</Icon>&nbsp;&nbsp;Adv.°: </span>{item.advogados[0].nome != null ? item.advogados[0].nome : ""}
              </Typography>
            </Grid>
          </Grid>)
        })}
      </span>
    )
  }
export default SustentacaoOral;