import React, { useState, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { Typography, Table, Grid, TableBody, TableCell, TableHead, TableRow, AppBar } from '@material-ui/core';
import { sortByOrdemPregao } from '../../../util/processo';
import { AppContext } from '../../../components/servidor/shared/context';

const PainelFinalizadosConteudo = (props) => {

  /*state = {
    processos: [],
    sessao: null
  }*/
	
	const [processos, setProcessos] = useState(sortByOrdemPregao(props.processos).filter((e) => { return e.resultadoJulgamento.descricao !== "Não julgado" }));
	const [sessao, setSessao] = useState(props.sessao);
	
  /*componentDidMount() {
    this.setState({
      processos: sortByOrdemPregao(this.props.processos).filter((e) => { return e.resultadoJulgamento.descricao !== "Não julgado" }),
      sessao: this.props.sessao
    })
  }*/
	
	const context = useContext(AppContext);
	
  /*render() {*/
    return (
      <div>

        <Grid container  justifyContent="center" style={{ color: "white", backgroundColor: "#1976d2", alignItems: "center" }}>
          <Grid item style={{padding:5}} >
            <Typography variant={"h6"} style={{color:"white"}}>
              ÚLTIMOS PROCESSOS APRECIADOS
          </Typography>
          </Grid>
        </Grid>

        <Container style={{ width: 1000, marginTop: 50 }}>
          <Card  >
            <CardContent style={{ textAlign: "center" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"><Typography style={{fontWeight:"bold"}}>PROCESSO</Typography></TableCell>
                    <TableCell align="left"><Typography style={{fontWeight:"bold"}}>RELATOR</Typography></TableCell>
                    <TableCell align="left"><Typography style={{fontWeight:"bold"}}>RESULTADO</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processos.map((row, index) => {
                    return (<TableRow key={row.processo.numeroCompleto}>
                      <TableCell id={"idNumeroCompleto" + index} align="left">
                        <Typography>
                          {row.processo.numeroCompleto}
                        </Typography>
                       
                      </TableCell>
                      <TableCell align="left">  <Typography>{row.processo.relator ? row.processo.relator.nome : ''}</Typography></TableCell>
                      <TableCell align="left">  <Typography>{row.resultadoJulgamento ? row.resultadoJulgamento.descricao : ''}</Typography></TableCell>
                    </TableRow>)
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Container>
      </div>
    )
  /*}*/
}

export default PainelFinalizadosConteudo;