import moment from 'moment';
import { getOrgaoJudicantes, getAdvogadoComCpf } from './Service';
import { STORAGES } from "../app/Storages";
const axios = require('axios');
const qs = require('querystring');
const { getUrlApi } = require('../util/api');
export const LOGIN_URL = "/login";

const TEMPO_LIMITE_PARA_RENOVAR_TOKEN_SEGUNDOS = 60;

export const isAuthenticated = () => localStorage.getItem(STORAGES.USUARIO_TOKEN_KEY) !== null;

export const getToken = () => JSON.parse(localStorage.getItem(STORAGES.USUARIO_TOKEN_KEY));

export const getTokenBase64 = () => JSON.parse(localStorage.getItem(STORAGES.USUARIO_TOKEN_KEY_BASE64));

export const getUsuario = () => JSON.parse(localStorage.getItem(STORAGES.USUARIO_INTERNO));

export const getUsuarioAdvogado = () => JSON.parse(localStorage.getItem(STORAGES.USUARIO_ADVOGADO));

export const logout = () => {
  localStorage.removeItem(STORAGES.USUARIO_TOKEN_KEY);
  localStorage.removeItem(STORAGES.USUARIO_TOKEN_KEY_BASE64);
  localStorage.removeItem(STORAGES.USUARIO_INTERNO);
  localStorage.removeItem(STORAGES.USUARIO_ADVOGADO);
  localStorage.removeItem(STORAGES.ORGAOS_COLEGIADOS);
}

export const logarAdvogado = async (login, password) => {
  console.log('Iniciando logarAdvogado...');
  logout();
  let body = {
    login: login,
    password: password
  };
  let urlApi = await getUrlApi('REACT_APP_LOGUS');
  let authCodeResponse = await axios.post(urlApi + '/pje/autenticacao', qs.stringify(body))
                                    .then(function (response) {
                                      console.log(response);
                                    })
                                    .catch(function (error) {
                                      if (error.response) {
                                        console.error(error.response.data);
                                        console.error(error.response.status);
                                        console.error(error.response.headers);
                                      } else if (error.request) {
                                        console.error(error.request);
                                      } else {
                                        console.error('Error', error.message);
                                      }
                                      console.error(error.config);
                                    });

  if (!authCodeResponse) {
    throw new Error(`Falha de autenticação (não foi possível recuperar um código de autenticação para o advogado ${login})`);
  }

  let resp = await getTokenByAuthCodeParaAdvogado(authCodeResponse.data.authCode, urlApi);
  let usuario = Buffer.from(getToken().token, 'base64').toString('utf8');

  localStorage.setItem(STORAGES.USUARIO_INTERNO, usuario);

  let cpf = usuario.substr(usuario.indexOf('cpf') + 6, 11);

  // Tratar para saber se é advogado
  try {
    let advogado = await getAdvogadoComCpf(cpf, 'PJe--1');
    localStorage.setItem(STORAGES.USUARIO_ADVOGADO, JSON.stringify(advogado));
  } catch (e) {
    console.error(e.message);
    throw new Error('Você precisa autenticar como um advogado');
  }
  return resp.data;
}

function calcularDuracaoRemanescente(hora, token) {
  console.log('Iniciando calcularDuracaoRemanescente...');

  if (!token || !token.horaCriacao) {
    return 0;
  }

  const UM_SEGUNDO = 1000;
  let horaCriacao = new Date(token.horaCriacao).getTime();
  let tempoDecorrido = hora.getTime() - horaCriacao;
  let duracaoRemanescente = (token.duracao * UM_SEGUNDO - tempoDecorrido) / UM_SEGUNDO;
  return Math.max(duracaoRemanescente, 0);
}

export const agendarRenovacaoToken = async () => {
  console.log('Iniciando agendamento de renovação de token...');
  let token = await getToken();
  let horaAtual = new Date();
  let tempoAgendamentoMilisegundos = (calcularDuracaoRemanescente(horaAtual, token) - TEMPO_LIMITE_PARA_RENOVAR_TOKEN_SEGUNDOS) * 1000;

  let dataHoraFormatadaAgendamento = (moment(horaAtual).add(tempoAgendamentoMilisegundos, 'ms')).format('DD/MM/YYYY HH:mm:ss');
  console.log('Renovação de token agendada para: ' + dataHoraFormatadaAgendamento);

  window.setTimeout(async function () {
    try {
      await renovarToken();
      await agendarRenovacaoToken();
    } catch (e) {
      console.error(`Falha na renovação do token: ${e.message}. Desistindo da renovação...`);
      //logout();
    }
  }, tempoAgendamentoMilisegundos);
}

export const renovarToken = async () => {
  console.log('Iniciando renovarToken...');
  let formData = new FormData();
  formData.append('tokenAntigo', getToken().token);
  let urlApi = await getUrlApi('REACT_APP_LOGUS');
  let url = urlApi + '/renovar-token';
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json'
    },
    body: formData
  });
  if (!response.ok) {
    throw new Error(response.status + ' - ' + response.statusText);
  }
  let body = await response.json();
  body.horaCriacao = new Date();
  localStorage.setItem(STORAGES.USUARIO_TOKEN_KEY, JSON.stringify(body));
  return body;
}

export const getTokenByAuthCode = async (authCode, urlApi) => {
  console.log('Iniciando getTokenByAuthCode...');
  let resp = await axios.get(urlApi + "/recuperar-token?authCode=" + authCode);
  resp.data.horaCriacao = new Date();
  localStorage.setItem(STORAGES.USUARIO_TOKEN_KEY, JSON.stringify(resp.data));
  let orgoesJudicantes = await getOrgaoJudicantes();
  localStorage.setItem(STORAGES.ORGAOS_COLEGIADOS, JSON.stringify(orgoesJudicantes.data));
  return resp.data;
}

export const getTokenByAuthCodeParaAdvogado = async (authCode, urlApi) => {
  console.log('Iniciando getTokenByAuthCodeParaAdvogado...');
  let resp = await axios.get(urlApi + "/recuperar-token?authCode=" + authCode)
                        .then(function (response) {
                          console.log(response);
                        })
                        .catch(function (error) {
                          if (error.response) {
                            console.error(error.response.data);
                            console.error(error.response.status);
                            console.error(error.response.headers);
                          } else if (error.request) {
                            console.error(error.request);
                          } else {
                            console.error('Error', error.message);
                          }
                          console.error(error.config);
                        });

  if (!resp) {
    throw new Error(`Falha de autenticação (código de autenticação inválido [${authCode}]).`);
  }

  localStorage.setItem(STORAGES.USUARIO_TOKEN_KEY, JSON.stringify(resp.data));
  let orgoesJudicantes = await getOrgaoJudicantes();
  localStorage.setItem(STORAGES.ORGAOS_COLEGIADOS, JSON.stringify(orgoesJudicantes.data));
  return resp.data;
}

export const logarServidor = async (login, password) => {
  console.log('Iniciando logarServidor...');
  logout();
  let urlApi = await getUrlApi('REACT_APP_LOGUS');
  let dados = {
    login: login,
    password: password
  };
  let resp = await axios.post(urlApi + '/sso/autenticacao', qs.stringify(dados));
  localStorage.setItem(STORAGES.USUARIO_TOKEN_KEY_BASE64, JSON.stringify(resp.data.token));
  return resp.data;
}

export const logarServidorComLocalizacao = async (localizacao) => {
  console.log('Iniciando logarServidorComLocalizacao...');
  let urlApi = await getUrlApi('REACT_APP_LOGUS');
  const dados = 'dados={token:"' + await getTokenBase64() + '",localizacao:"' + localizacao + '"}';
  const origem = 'origem=' + window.location.origin;
  const tipoUsuario = 'tipo-usuario=SSO--USUARIO-INTERNO';
  const body = dados + "&" + origem + "&" + tipoUsuario;
  let resp = await axios.post(urlApi + '/gerar-token', body);
  return getTokenByAuthCode(resp.data.authCode, urlApi);
}
