import PainelSessaoConteudo from "../../../components/servidor/painel/index";
import PainelFinalizadosConteudo from "../../../components/servidor/painel/finalizados";


var indexRoutes = [
    { path: "/servidor/painel", component: PainelSessaoConteudo },
    { path: "/servidor/painelFinalizados", component: PainelFinalizadosConteudo },
];

export default indexRoutes;
