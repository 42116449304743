import React, { useState, useContext, useEffect } from 'react';

import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';

import { AppContext } from '../shared/context';
import './selecao.css';

import moment from "moment";

const ComboSessao = () => {
  const context = useContext(AppContext)
  const [sessaoSelecionada, setSessaoSelecionada] = useState('selecione');

  useEffect(() => {
    if (context.sessoes) {
      let sessaoStorage = JSON.parse(localStorage.getItem('sessao'));
      if (sessaoStorage) {
        context.sessoes.map((sessao) => {
          if (sessao.id === sessaoStorage.id) {
            console.log("Sessao: " + sessao.id);
            context.defineSessao(sessao);
            setSessaoSelecionada(sessao);
          }
        });
      }
    }
  }, [context.sessoes]);

  /*const formataData = (valor) => {
    if (valor !== null) {
      return valor.slice(0, 10);
    } else {
      return '';
    }
  }*/

  const ORIGEM_PJE = 'PJe';
  const ORIGEM_LEGADO = 'Esij'

  let getDescricaoOpcaoSessao = (sessao) => {
    const chavesDescricaoPorSessao = Object.keys(sessao.descricaoPorSessao);
    let descricaoSessao;
    if (chavesDescricaoPorSessao.length === 1 || isTodasSessoesMesmoHorario(sessao)) {
        descricaoSessao = getHoraSessao(sessao, chavesDescricaoPorSessao[0]) + ': ' + sessao.descricao;
    } else {
        descricaoSessao = getDescricaoSessaoCompostaHorariosDivergentes(sessao);
    }
    return <span>
        {sessao.tipo + " - " + moment(sessao.prazos.dataInicioSessaoPresencial, 'DD-MM-YYYY-HH-mm-ss').format('DD/MM/YYYY') + ' '}
        {descricaoSessao}
        {getDescricaoTipoJulgamento(sessao) + getSinalizadorProximaSessao(sessao)}
    </span>;
}

let isTodasSessoesMesmoHorario = (sessao) => {
  let horasSessoes = Object.keys(sessao.prazosPorSessao).map(key => getHora(sessao.prazosPorSessao[key].dataInicioSessaoPresencial));
  return horasSessoes.every(horaSessao => horaSessao === horasSessoes[0]);
}

let getSinalizadorProximaSessao = (sessao) => {
  return (sessao.proxima ? " [Próxima]" : "") + (sessao.hoje ? " [Hoje]" : "");
}

let getDescricaoTipoJulgamento = (sessao) => {
  return (sessao.tipoJulgamento === "TELEPRESENCIAL" ? " - " + getTipoJulgamentoCapitalizado(sessao) : "");
}

let getTipoJulgamentoCapitalizado = (sessao) =>{
  return sessao.tipoJulgamento.charAt(0).toUpperCase() + sessao.tipoJulgamento.slice(1).toLowerCase();
}

let getHoraSessao = (sessao, sessaoId) => {
  return getHora(sessao.prazosPorSessao[sessaoId].dataInicioSessaoPresencial);
}

let getHora = (data) => {
  return moment(data, 'DD-MM-YYYY-HH-mm-ss').format('HH:mm');
}

let getDescricaoSessaoCompostaHorariosDivergentes = (sessao) => {
    let horasDescricoes = Object.keys(sessao.descricaoPorSessao).reduce((acc, sessaoId) => {
        const horaSessao = getHoraSessao(sessao, sessaoId);
        let horaSessoes = acc.find(horaDesc => horaDesc.horaSessao === horaSessao);
        if (!horaSessoes) {
            horaSessoes = { horaSessao: horaSessao, sessoesPJe: [], sessoesLegado: [] };
            acc.push(horaSessoes);
        }
        if (sessaoId.indexOf(ORIGEM_PJE) > -1) {
            horaSessoes.sessoesPJe.push(sessao.descricaoPorSessao[sessaoId].split(' ')[1]);
        } else {
            horaSessoes.sessoesLegado.push(sessao.descricaoPorSessao[sessaoId].split(' ')[1]);
        }
        return acc;
    }, []);
    horasDescricoes.sort((a, b) => a.horaSessao.localeCompare(b.horaSessao));
    horasDescricoes.forEach(horaDescricao => {
        horaDescricao.sessoesLegado = horaDescricao.sessoesLegado.sort((a, b) => a.localeCompare(b));
        horaDescricao.sessoesPJe = horaDescricao.sessoesPJe.sort((a, b) => a.localeCompare(b));
    });
    return horasDescricoes.map((horaDescricao, index) => {
        return <span key={index}>
            <b>{horaDescricao.horaSessao + ': '}</b>
            {horaDescricao.sessoesLegado.length > 0 && ORIGEM_LEGADO + ' ' + horaDescricao.sessoesLegado.join(', ')}
            {horaDescricao.sessoesLegado.length > 0 && horaDescricao.sessoesPJe.length > 0 && '; '}
            {horaDescricao.sessoesPJe.length > 0 && ORIGEM_PJE + ' ' + horaDescricao.sessoesPJe.join(', ')}
            {index < horasDescricoes.length - 1 && '; '}
        </span>;
    });
}

  return (
    <Select
      title="Clique para selecionar outra sessão. Legenda: Tipo de sessão (Ordinária/Extraordinária) - Data da sessão presencial: Número da sessão"
      className="selecao"
      variant="outlined"
      value={sessaoSelecionada}
      onChange={async (e) => {
        context.defineSessao(e.target.value);
        context.setIsShowPecas(false);
        localStorage.setItem("sessaoId", e.target.value.id);
        localStorage.setItem("sessao", JSON.stringify(e.target.value));
        setSessaoSelecionada(e.target.value);
      }}
      IconComponent={() => null}
    >
      <MenuItem value="selecione">Selecione uma sessão...</MenuItem>
      {context.sessoes && context.sessoes.length > 0 && context.sessoes.map(sessao => (
        <MenuItem key={sessao.id} value={sessao}>{getDescricaoOpcaoSessao(sessao)}</MenuItem>
      ))
      })
    </Select >
  )
}

export default ComboSessao;