import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import indexRoutes from './routes/layout/index';
import theme from './theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

const versaoOrigem = () => {
    let packageJson = require('../package.json');
    return packageJson.version;
}

const hist = createBrowserHistory();

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Router history={hist}>
            <Switch>
                {indexRoutes.map((prop, key) => {
                    return <Route path={prop.path} key={key} component={prop.component} />;
                })}
            </Switch>
        </Router>
        <footer style={{color: "white", position: "fixed", bottom: 0, backgroundColor: 'grey', width: '100%', height: '25px', marginLeft: '-8px', paddingRight: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Roboto', fontSize: '11pt'}}>
            <div>
                Potius - Versão: {versaoOrigem()}
            </div>
        </footer>
        </MuiThemeProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
