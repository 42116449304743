import axios from './axiosConfig';
import swal from 'sweetalert2';

const {
  getUrlApi
} = require('../util/api');

export const getSessoes = async (orgao) => {
  console.log("Iniciando Service.getSessoes... [OJC: " + orgao + "] ");
  try {
    var urlApi = await getUrlApi('REACT_APP_SESSAO');
    let resp = await axios.get(urlApi + `/orgaos-judicantes/${orgao}/sessoes`);
    console.log("Finalizado Service.getSessoes. [OJC: " + orgao + "] ");
    return resp.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getSessoesPublica = async (orgao) => {
  console.log("Iniciando Service.getSessoesPublica... [OJC: " + orgao + "]");
  try {
    var urlApi = await getUrlApi('REACT_APP_SESSAO');
    let resp = await axios.get(urlApi + `/orgaos-judicantes/${orgao}/sessoes`);
    console.log("Finalizado Service.getSessoesPublica. [OJC: " + orgao + "]");
    return resp.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getSessaoDetalhada = async (idSessao) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp = null;

  try {
    console.log("Iniciando Service.getSessaoDetalhada... [Sessão: " + idSessao + "]");
    let valor = await axios.get(urlApi + '/sessoes/' + idSessao + '?nivelDetalhes=SalaSessao&ordenacao=SalaSessao');
    localStorage.setItem("sessaoDetalhada", JSON.stringify(valor.data));
    resp = valor.data;
    console.log("Finalizado Service.getSessaoDetalhada. [Sessão: " + idSessao + "]");
  } catch (error) {
    console.error(error);
    return resp;
  }
  return resp;
}

export const getProcessoDetalhado = async (idSessao, numeroProcessoFormatado) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp = null;

  try {
    console.log("Iniciando Service.getProcessoDetalhado... [Sessão: " + idSessao + "; numeroProcessoFormatado: " + numeroProcessoFormatado + "]");
    let valor = await axios.get(urlApi + '/sessoes/' + idSessao + '/processos/' + numeroProcessoFormatado);
    resp = valor.data;
    console.log("Finalizado Service.getProcessoDetalhado. [Sessão: " + idSessao + "; numeroProcessoFormatado: " + numeroProcessoFormatado + "]");
  } catch (error) {
    console.error(error);
    return resp;
  }
  return resp;
}

export const getDocumentos = async (processo, idSessao) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp = null;

  try {
    console.log("Iniciando Service.getDocumentos... [Sessão: " + idSessao + "; processo: " + processo + "]");
    let valor = await axios.get(urlApi + '/sessoes/' + idSessao + '/processos/' + processo + "/documentos");
    resp = valor.data;
    console.log("Finalizado Service.getDocumentos. [Sessão: " + idSessao + "; processo: " + processo + "]");
  } catch (error) {
    swal.fire({
      title: "Erro",
      text: "Ocorreu um erro no servidor para disponibilizar os documentos",
      type: "error",
      confirmButtonText: "OK"
    })
    console.error(error);
    return resp;
  }
  return resp;
}

export const getDownloadDocumento = async (processo, idSessao, idDocumento, tipoDocumento) => {
  let resp = null;

  try {
    console.log("Iniciando Service.getDownloadDocumento... [Sessão: " + idSessao + "; processo: " + processo + "; idDocumento: " + idDocumento + "; tipoDocumento: " + tipoDocumento + "]");
    var urlApi = await getUrlApi('REACT_APP_SESSAO');
    let valor;
    let url = urlApi + `/documentos/${idSessao}/${processo}/${idDocumento}/${tipoDocumento}/conteudo`;

    if (tipoDocumento === 'PDF') {
      valor = await axios.get(
        url, {
        responseType: 'arraybuffer',
        headers: {
          accept: 'application/octet-stream'
        }
      });
    } else if (tipoDocumento === 'HTML') {
      valor = await axios.get(
        url, {
        headers: {
          accept: 'text/html'
        }
      });
    }
  
    console.log("Finalizado Service.getDownloadDocumento. [Sessão: " + idSessao + "; processo: " + processo + "; idDocumento: " + idDocumento + "; tipoDocumento: " + tipoDocumento + "]");
    return valor.data;
  } catch (error) {
    console.error(error);
    return resp;
  }
}

export const getOrgaoJudicantes = async () => {
  console.log("Iniciando Service.getOrgaoJudicantes...");
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp = await axios.get(urlApi + `/orgaos-judicantes`);
  console.log("Finalizado Service.getOrgaoJudicantes.");
  return resp;
}

export const getProcessos = async (idSessao) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  console.log("Iniciando Service.getProcessos... [Sessão: " + idSessao + "]");
  let resp = await axios.get(urlApi + `/sessoes/${idSessao}/processos`);
  console.log("Finalizado Service.getProcessos. [Sessão: " + idSessao + "]");
  return resp.data;
}

export const getProcessoApregoadoPorOrgao = async (orgao) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  try {
    console.log("Iniciando Service.getProcessoApregoadoPorOrgao... [OJC: " + orgao + "]");
    let resp = await axios.get(urlApi + `/pregoes/${orgao}`);
    console.log("Finalizado Service.getProcessoApregoadoPorOrgao. [OJC: " + orgao + "]");
    return resp;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getProcessoApregoado = async (orgaoSigla, sessaoId) => {
  let respProcessoApregoado;

  try {
    console.log("Iniciando Service.getProcessoApregoado... [OJC: " + orgaoSigla + "]");
    respProcessoApregoado = await getProcessoApregoadoPorOrgao(orgaoSigla);

    if (!respProcessoApregoado || !respProcessoApregoado.data.numeroProcessoFormatado) {
      return '';
    }

    let localStorageSessaoCompleta = localStorage.getItem("sessaoDetalhada");
    let sessaoCompleta = await JSON.parse(localStorageSessaoCompleta);

    if (!sessaoCompleta || !sessaoCompleta.processos || !respProcessoApregoado.data.numeroProcessoFormatado) {
      return '';
    }

    let arrFilterProcessoApregoado = sessaoCompleta.processos.find(p => p.processo.numeroCompleto === respProcessoApregoado.data.numeroProcessoFormatado.toString())

    let processoApregoado;
    if (arrFilterProcessoApregoado) {
      processoApregoado = arrFilterProcessoApregoado;
    }

    let processoDetalhado;
    if (processoApregoado) {
      let sessaoProcessoApregoado = processoApregoado.sessaoId;
      console.log('Sessão do processo apregoado (' + respProcessoApregoado.data.numeroProcessoFormatado + '): ' + sessaoProcessoApregoado);

      processoDetalhado = await getProcessoDetalhado(sessaoProcessoApregoado, respProcessoApregoado.data.numeroProcessoFormatado);

      console.log("Processo detalhado:");
      console.log(processoDetalhado);
    }

    if (processoDetalhado) {
      processoDetalhado.processoApregoado = respProcessoApregoado.data;
    }

    console.log("Finalizado Service.getProcessoApregoado. [OJC: " + orgaoSigla + "]");
    return processoDetalhado;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getProcesso = async (sessao, numeroProcesso) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp = null;

  try {
    console.log("Iniciando Service.getProcesso... [Sessão: " + sessao + "; numeroProcesso: " + numeroProcesso + "]");
    resp = await axios.get(urlApi + `/sessoes/${sessao}/processos/${numeroProcesso}`);
    console.log("Finalizado Service.getProcesso. [Sessão: " + sessao + "; numeroProcesso: " + numeroProcesso + "]");
    return resp;
  } catch (error) {
    console.error(error);
    return resp;
  }
}

export const getProcessoDetalhe = async (sessao, numeroProcesso) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp;

  console.log("Iniciando Service.getProcessoDetalhe... [Sessão: " + sessao + "; numeroProcesso: " + numeroProcesso + "]");
  resp = await axios.get(urlApi + `/sessoes/${sessao}/processos/${numeroProcesso}/detalhes`);
  console.log("Finalizado Service.getProcessoDetalhe. [Sessão: " + sessao + "; numeroProcesso: " + numeroProcesso + "]");
  return resp;
}

export const getPlanilha = async (orgao) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');

  console.log("Iniciando Service.getPlanilha... [OJC: " + orgao + "]");
  let resp = await axios.get(urlApi + `/pregoes/${orgao}`);
  console.log("Finalizado Service.getPlanilha. [OJC: " + orgao + "]");
  return resp.data;
}

export const getSessaoFutura = async (numeroProcesso) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp;

  console.log("Iniciando Service.getSessaoFutura... [numeroProcesso: " + numeroProcesso + "]");
  resp = await axios.get(urlApi + `/processos/${numeroProcesso}/sessao-futura`);
  console.log("Finalizado Service.getSessaoFutura. [numeroProcesso: " + numeroProcesso + "]");
  return resp.data;
}

export const getProcessosAdvogadoFazParte = async (numeroOab, siglaOab) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp;

  console.log("Iniciando Service.getProcessosAdvogadoFazParte... [numeroOab: " + numeroOab + "; siglaOab: " + siglaOab + "]");
  resp = await axios.get(urlApi + `/processos/advogado-faz-parte/` + numeroOab + ',' + siglaOab + `?origem=potius`);
  console.log("Finalizado Service.getProcessosAdvogadoFazParte. [numeroOab: " + numeroOab + "; siglaOab: " + siglaOab + "]");

  return resp.data;
}

export const salvarPedidoSustentacao = async (pedido, sessaoId, numeroProcesso) => {
  var urlApi = await getUrlApi('REACT_APP_SECRETARIA');

  console.log("Iniciando Service.salvarPedidoSustentacao... [pedido: " + pedido + "; sessaoId: " + sessaoId + "; numeroProcesso: " + numeroProcesso + "]");
  let resp = await axios.post(urlApi + `/sessoes/${sessaoId}/processos/${numeroProcesso}/representantes?origem=potius`, pedido);
  enviarNotificacao(sessaoId, numeroProcesso);
  console.log("Finalizado Service.salvarPedidoSustentacao. [pedido: " + pedido + "; sessaoId: " + sessaoId + "; numeroProcesso: " + numeroProcesso + "]");

  return resp.data;
}

export const excluirPedidoSustentacao = async (sessaoId, numeroProcesso, id) => {
  var urlApi = await getUrlApi('REACT_APP_SECRETARIA');

  console.log("Iniciando Service.excluirPedidoSustentacao... [sessaoId: " + sessaoId + "; numeroProcesso: " + numeroProcesso + "; id: " + id + "]");
  let resp = await axios.delete(urlApi + `/sessoes/${sessaoId}/processos/${numeroProcesso}/representantes/${id}?origem=potius`);
  enviarNotificacao(sessaoId, numeroProcesso);
  console.log("Finalizado Service.excluirPedidoSustentacao. [sessaoId: " + sessaoId + "; numeroProcesso: " + numeroProcesso + "; id: " + id + "]");
  return resp.data;
}

export const enviarNotificacao = async (sessaoId, numeroProcesso) => {
  var urlApi = await getUrlApi('REACT_APP_ENVELOPE');
  let resp = null;

  console.log("Iniciando Service.enviarNotificacao... [sessaoId: " + sessaoId + "; numeroProcesso: " + numeroProcesso + "]");
  try {
    resp = await axios.post(urlApi + `/notificacao/sessao/${sessaoId}/processo?processo=${numeroProcesso}`);
    console.log("Finalizado Service.enviarNotificacao. [sessaoId: " + sessaoId + "; numeroProcesso: " + numeroProcesso + "]");
    return resp;
  } catch (error) {
    console.error(error);
    return resp;
  }
}

export const getPecas = async (numeroProcesso) => {
  var urlApi = await getUrlApi('REACT_APP_BACKEND');
  let resp = null;

  console.log("Iniciando Service.getPecas... [numeroProcesso: " + numeroProcesso + "]");
  try {
    resp = await axios.get(urlApi + `/processo-trt/${numeroProcesso}/pecas`);
    console.log("Finalizado Service.getPecas. [numeroProcesso: " + numeroProcesso + "]");
    return resp.data;
  } catch (error) {
    console.error(error);
    return resp;
  }
}

export const getConteudoPeca = async (numeroProcesso, codigoPeca) => {
  let resp = null;

  try {
    console.log("Iniciando Service.getConteudoPeca... [numeroProcesso: " + numeroProcesso + "; codigoPeca: " + codigoPeca + "]");
    var urlApi = await getUrlApi('REACT_APP_BACKEND');
    resp = await axios.get(urlApi + `/processo-trt/${numeroProcesso}/pecas/${codigoPeca}/conteudo`);
    console.log("Finalizado Service.getConteudoPeca. [numeroProcesso: " + numeroProcesso + "; codigoPeca: " + codigoPeca + "]");
    return resp;
  } catch (error) {
    console.error(error);
    return resp;
  }
}

export const getAdvogadoComCpf = async (cpf, sessaoId) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp = null;
  console.log("Iniciando Service.getAdvogadoComCpf... [cpf: " + cpf + "; sessaoId: " + sessaoId + "]");
  resp = await axios.get(urlApi + '/sessoes/' + sessaoId + '/advogados/' + cpf);
  console.log("Finalizado Service.getAdvogadoComCpf. [cpf: " + cpf + "; sessaoId: " + sessaoId + "]");
  return resp.data;
}

export const getAdvogadoComOab = async (numeroOab, siglaOab, sessaoId) => {
  var urlApi = await getUrlApi('REACT_APP_SESSAO');
  let resp = null;
  console.log("Iniciando Service.getAdvogadoComOab... [numeroOab: " + numeroOab + "; siglaOab: " + siglaOab + "; sessaoId: " + sessaoId + "]");
  resp = await axios.get(urlApi + '/sessoes/' + sessaoId + '/advogados/' + numeroOab + ',' + siglaOab);
  console.log("Finalizado Service.getAdvogadoComOab. [numeroOab: " + numeroOab + "; siglaOab: " + siglaOab + "; sessaoId: " + sessaoId + "]");
  return resp.data;
}
