import React, { createContext, useState } from "react";
//import moment from 'moment';

export const Context = createContext({});

export const Provider = props => {
  // Initial values are obtained from the props
  const {
    children
  } = props;

  const [tituloBar, setTituloBar] = useState();
  const [processos, setProcessos] = useState();
  const [orgaos, setOrgaos] = useState();
  const [orgao, setOrgao] = useState();
  const [sessaoSelecionada, setSessaoSelecionada] = useState();
  const [sessoes, setSessoes] = useState();
  const [processoApregoado, setProcessoApregoado] = useState();
  const [isLoading, setLoading] = useState(false);

  

  const defineTituloBar = (titulo) => {
    setTituloBar(titulo);
  }

  const defineProcessos = (processos) => {
    setProcessos(processos);
  }

  const defineOrgao = async (orgao) => {
    setOrgao(orgao);
  }

  const defineSessoesDoDia = async (sessoes) => {
    //TODO aplicar o filtro quando for criadas as sessões diárias
    //let hoje = moment(new Date()).format("DD-MM-YYYY");
    //let lista = sessoes.filter((e) => e.prazos.dataInicioSessaoPresencial.includes(hoje));
    setSessoes(sessoes);
    return sessoes;
  }

  const defineSessaoSelecionada = (sessao) => {
    setSessaoSelecionada(sessao);
  }

  const defineListaOrgaos = (listaOrgaos) => {
    setOrgaos(listaOrgaos);
  }

  const defineProcessoApregoado = (processo) => {
    setProcessoApregoado(processo);
  }

  // Make the context object:
  const appContext = {
    defineSessaoSelecionada,
    defineSessoesDoDia,
    defineProcessos,
    defineTituloBar,
    defineListaOrgaos,
    defineOrgao,
    defineProcessoApregoado,
    tituloBar, setTituloBar,
    orgaos, setOrgaos,
    sessaoSelecionada, setSessaoSelecionada,
    processoApregoado, setProcessoApregoado,
    isLoading, setLoading,
    processos, setProcessos,
    sessoes, setSessoes,
    orgao, setOrgao
  };

  // pass the value in provider and return
  return <Context.Provider value={appContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

