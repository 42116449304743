import React, { useState, useContext, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { AppBar, Toolbar, IconButton, Typography, Grid, Icon, LinearProgress, Card, CardHeader, CardActions } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { Route, Switch, Redirect, Link } from "react-router-dom";
import routes from '../../../routes/advogado/components/default'
import { isAuthenticated, LOGIN_URL, logout } from '../../../services/AuthService';
import { AppContextProvider, AppContextConsumer, AppContext } from '../../../components/advogado/shared/context';

export default function CustomDrawer() {
	
    const context = useContext(AppContext);
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    const menu = [
        { "nome": "Pedido de sustentação oral", "path": "/advogado", "target": "_black" }]

    const sideList = side => (
        <div
            style={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                {menu.map((item, index) => (
                    <ListItem key={index} component={props => <Link to={item.path} target={item.target} {...props} />}>
                        <ListItemText primary={item.nome} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div>
            <AppContextProvider>
                <AppBar
                    style={{ backgroundColor: "#01579b", height: 60 }}
                    position="fixed"
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer('left', true)}
                            edge="start"
                        >
                            <Menu />
                        </IconButton>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between" >
                                    <AppContextConsumer>
                                        {context => (context.tituloBar && <Grid item>
                                            <Typography style={{ color: "white" }} variant="h6">{context.tituloBar}</Typography>
                                        </Grid>)}
                                    </AppContextConsumer>
                                    <Grid item>
                                        <Link style={{ color: "white" }} to="/login" onClick={() => logout()}><Icon>exit_to_app</Icon></Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                    {sideList('left')}
                </Drawer>
                <main>
                    <AppContextConsumer>
                        {context => (context.isLoading && <LinearProgress />)}
                    </AppContextConsumer>


                    <Switch>
                        {routes.map((prop, key) => {
                            if (!isAuthenticated() && prop.path !== LOGIN_URL) {
                                return <Redirect from={prop.path} to={LOGIN_URL} key={key} />;
                            } else if (prop.redirect) {
                                return <Redirect from={prop.path} to={prop.pathTo} showPesquisaProcesso={true} open={false} key={key} />;
                            }
                            return (
                                <Route exact path={prop.path} component={prop.component} key={key} />
                            );
                        })}
                    </Switch>
                </main>
            </AppContextProvider>
        </div>
    );
}