import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {logarServidorComLocalizacao} from '../../services/AuthService'
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SimpleDialog(props) {
  const { open, localizacoes, isAdvogado } = props;
  const [loading, setLoading] = useState(props.loading);

 const handleListItemClick = async event => {
    setLoading(true);
    localStorage.setItem('local', event.currentTarget.value);
    await logarServidorComLocalizacao(event.currentTarget.value);
    
    //agendarRenovacaoToken(getToken().token);
    
    if(isAdvogado){
      window.location = '/advogado';
    }else{
      window.location = '/servidor/home';
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  return (
    <Fragment>
      {loading ? 
        <CircularProgress style={{  marginTop: '5px'  }}/>
        :
        <Dialog width={300} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="simple-dialog-title">Selecione a localização</DialogTitle>
          <List>
            {localizacoes.map(local => (
              <ListItem button  key={local}>
                <Button fullWidth key={local} value={local} onClick={(local) => {handleListItemClick(local) }}>{local}</Button>
              </ListItem>
            ))}
          </List>
        </Dialog>}
    </Fragment>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  localizacoes: PropTypes.array.isRequired
};
