import { CONFIGURACAO_APP } from "../app/Configuracao";

/**
 * Classe utilitária para agrupar métodos de Storages.
 * 
 * @class
 * @copyright Tribunal Superior do Trabalho 2022
 * @author Eduardo Carvalho <eduardo.carvalho@tst.jus.br>
 */
 export default class StorageUtil {
    /**
     * Prefixo para os nomes dos storages do sistema
     * @constant {string}
     */
    static PREFIXO_NOME_STORAGE = CONFIGURACAO_APP.PREFIXO_NOME_STORAGE;

    static setItem(store, value) {
        if ((typeof store === 'string' || store instanceof String) && (typeof value === 'string' || value instanceof String)) {
            if (store.startsWith(StorageUtil.PREFIXO_NOME_STORAGE)) {
                localStorage.setItem(store, value);
            } else {
                localStorage.setItem(StorageUtil.PREFIXO_NOME_STORAGE + store, value);
            }
        } else {
            console.error(`O nome da store ou o valor informado(s) não é(são) do tipo string [${store},${value}].`);
        }
    }

    static getItem(store) {
        if (typeof store === 'string' || store instanceof String) {
            if (store.startsWith(StorageUtil.PREFIXO_NOME_STORAGE)) {
                return localStorage.getItem(store);
            } else {
                return localStorage.getItem(StorageUtil.PREFIXO_NOME_STORAGE + store);
            }
        }

        console.error(`O nome da store informada não é do tipo string [${store}].`);
        return null;
    }

    static getObject(storeComValorJson) {
        try {
            return JSON.parse(StorageUtil.getItem(storeComValorJson));
        } catch (error) {
            throw new Error(`Falha ao recuperar o objeto da storage.`, { cause: error });
        }
    }

    /**
     * Remove uma storage local.
     * 
     * @param {string|object} store O nome da storage ou uma instância de {store.Store}
     * @returns {boolean} Indicador de item excluído com sucesso ou não
     */
    static removeItem(store) {
        let storageName;

        if (typeof store === 'string' || store instanceof String) {
            storageName = (store.startsWith(StorageUtil.PREFIXO_NOME_STORAGE)) ? store : (StorageUtil.PREFIXO_NOME_STORAGE + store);
        } else if (typeof store === 'object' || store instanceof Object) {
            if (store.nomeStorage !== undefined) {
                storageName = (store.nomeStorage.startsWith(StorageUtil.PREFIXO_NOME_STORAGE)) ? store.nomeStorage : (StorageUtil.PREFIXO_NOME_STORAGE + store.nomeStorage);
            }
        }

        if (storageName === undefined) {
            console.log(`Não foi possível identificar a storage [${store}].`);
            return false;
        }

        console.log('Removendo storage ' + storageName + '...');
        localStorage.removeItem(storageName);
        return true;
    }

    /**
     * Remove todas as storages locais.
     * 
     * @param {Array} excecoes Lista de itens que não devem ser excluídos
     * @returns {number} Total de itens excluídos
     */
    static removeAll(excecoes) {
        let total = 0;
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).startsWith(StorageUtil.PREFIXO_NOME_STORAGE)) {
                if (!Array.isArray(excecoes) || !excecoes.includes(localStorage.key(i))) {
                    console.log('Removendo storage ' + localStorage.key(i) + '...');
                    localStorage.removeItem(localStorage.key(i));
                    total++;
                } else {
                    console.log(`${localStorage.key(i)} não será removida.`);
                }
            }
        }

        return total;
    }
}
