import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function AppBarCustom(props) {

  const {orgaoJudicante, processo, sessao, tipo, dataSessao} = props;
    return (
      <AppBar style={{backgroundColor: "#01579b"}}>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" >
                <Grid item>
                  <Typography variant="h6" style={{color:"white"}} >{orgaoJudicante}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" style={{color:"white"}}>PROCESSO APREGOADO: {processo ? processo : "Nenhum processo apregoado"}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" style={{color:"white"}}>SESSÃO {tipo ==='O'? 'ORDINÁRIA': 'EXTRAORDINÁRIA' } {dataSessao}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
}