import Home from "../../../components/servidor/home";
import Painel from "../../../components/servidor/painel";


var indexRoutes = [
    { path: "/servidor/home", component: Home },
    { path: "/servidor/painel", component: Painel }
];

export default indexRoutes;
