import LoginLayout from '../../layout/login';
import Default from '../../layout/servidor/default';
import LayoutAdvogado from '../../layout/advogado/default';
import Painel from '../../layout/painel'

var indexRoutes = [ 
    { path: "/servidor/home", component: Default },
    { path: "/servidor/painel", component: Painel },
    { path: "/servidor/painelFinalizados", component: Painel },

//rodas do módulo de advogado
    { path: "/advogado", component: LayoutAdvogado },


//Login

    { path: "/", component: LoginLayout },
    { path: "", component: LoginLayout }
];

export default indexRoutes;
