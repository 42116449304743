import axios from 'axios';
import { getToken } from '../services/AuthService';
import swal from 'sweetalert2';

axios.interceptors.request.use(async function (config) {
    console.log("axios.interceptors.request.use config");
    let token = await getToken();
    if (token != null) {
        config.headers.Authorization = `Basic ${token.token}`;
    }
    return config;
}, function (err) {
    console.error(err);
    return Promise.reject(err);
});

axios.interceptors.response.use(function (response) {
    console.log("axios.interceptors.request.use response");
    console.log(response);
    return response;
}, function (error) {
    if (403 === error.response.status) {
        swal.fire({
            title: "Falha de autorização",
            text: "Usuário sem autorização de acesso. Redirecionando para tela de autenticação...",
            type: "warning",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "OK",
            closeOnConfirm: false
        }).then(() => {
            window.location = "/login"
        })
    } else {
        console.error(error);
        return Promise.reject(error);
    }
});

export default axios;