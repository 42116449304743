import React from 'react';
import Countdown, { CountdownApi, zeroPad } from "react-countdown";
import { Typography } from '@material-ui/core';
require('../../services/EnvelopeMultiplosCanais');
const { getUrlApi } = require('../../util/api');
const TEMPO_MAX_SUSTENTACAO_MS = 10 * 60 * 1000;
const TEMPO_MAXIMO_SUSTENTACAO_CRONOMETRO = '10:00';

export default class Cronometro extends React.Component {

    state = { show: false, date: Date.now() + TEMPO_MAX_SUSTENTACAO_MS };
    countdownApi: CountdownApi | null = null;

    setRef = (countdown) => {
        if (countdown) {
            this.countdownApi = countdown.getApi();
        }
    };

    constructor(props) {
        super(props);
    }

    handleStartClick = () => {
        this.countdownApi && this.countdownApi.start();
    };

    handlePauseClick = () => {
        this.countdownApi && this.countdownApi.pause();
    };

    handleResetClick = () => {
        this.setState({ date: Date.now() + TEMPO_MAX_SUSTENTACAO_MS });
    };

    handleUpdate = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        this.handleResetClick();
        let sessaoLocal = JSON.parse(localStorage.getItem("sessao"));
        if (sessaoLocal && sessaoLocal.id) {
            this.iniciarEnvelopeProcessoApregoado(sessaoLocal, this);
        }
    }

    isPaused() {
        return !!(this.countdownApi && this.countdownApi.isPaused());
    }

    isCompleted() {
        return !!(this.countdownApi && this.countdownApi.isCompleted());
    }

    iniciarEnvelopeProcessoApregoado = async (sessao, cronometro) => {
        var settings = {
            url: await getUrlApi('REACT_APP_ENVELOPE') + '/cometd'
        };
        if (sessao) {
            let clienteEnvelope = window.envelope.novoClienteMultiplosCanais(settings);
            clienteEnvelope.iniciar(function () {
                let canalPregao = `/sessao/${sessao.id}/cronometro`;
                if (!clienteEnvelope.canalEstaRegistrado(canalPregao)) {
                    clienteEnvelope.registrarCanal(canalPregao, (mensagem) => {
                        let data = JSON.parse(mensagem.data);
                        if (data.operacao[0] === 'INICIAR') {
                            cronometro.setState({ "show": true })
                            cronometro.setState({ date: Date.now() + (1000 * data.tempo[0]) });
                            cronometro.handleStartClick();
                        }
                        if (data.operacao[0] === 'PAUSAR') {
                            cronometro.setState({ "show": true })
                            cronometro.handlePauseClick();
                        }
                        if (data.operacao[0] === 'PARAR') {
                            cronometro.setState({ "show": false })
                            cronometro.handleResetClick();
                        }
                    });
                }
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.show &&
                    <Countdown
                        key={this.state.date}
                        ref={this.setRef}
                        date={this.state.date}
                        onMount={this.handleUpdate}
                        onStart={this.handleUpdate}
                        onPause={this.handleUpdate}
                        onComplete={this.handleUpdate}
                        zeroPadTime={1}
                        autoStart={false}
                        renderer={props =>
                            <div style={{ borderStyle: "dotted", borderColor: "red" }}>
                                <Typography style={{ fontWeight: "bold" }} variant={"h4"}>{zeroPad(props.minutes, 2)}:{zeroPad(props.seconds, 2)}</Typography>
                            </div>
                        }
                    />}
                {!this.state.show && <Typography style={{ fontWeight: "bold" }} variant={"h5"}>{TEMPO_MAXIMO_SUSTENTACAO_CRONOMETRO}</Typography>}
            </div>
        )
    }
}