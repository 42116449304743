import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  root: {
    fontFamily: 'Bebas Neue',
  },
  palette: {
    primary: {
      main: '#01426A'
    },
    secondary: {
        main: '#C7A000'
      }
    },
    
    overrides: {
      MuiSelect: {
        root: {
          color: 'white',
          borderColor: "white"
        },
      },
      MuiTypography: {
          root: {
              color: "#01426A"
             
          }
      },
      MuiMenuItem: {
        root: {
          '&$selected': {
            color: 'black',
          },
        },
      },
    },
  });
  export default theme;