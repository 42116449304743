import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Container,
  Card,
  TextField,
  CardContent,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { logarAdvogado, logarServidor } from '../../services/AuthService'
import SimpleDialog from './modalUnidade';
import MySnackbarContentWrapper from '../snackbar/index';

class Login extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      usuario: '',
      senha: '',
      isServidor: true,
      isOpenModal: false,
      localizacoes: [],
      isLogado: false,
      openAlert: false,
      tipoAlerta: 'error',
      loading: false
    };
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openAlert: false });
  };

  logar = async (event) => {
    event.preventDefault();
    var retorno = false;
    if (!this.state.isServidor) {
      try {

       await logarAdvogado(this.state.usuario, this.state.senha);
       //window.location = '/advogado';
       
       //agendarRenovacaoToken(getToken().token);
       
       window.location = '/servidor/home';
       

       /*var resposta = await logarServidor(this.state.usuario, this.state.senha);
       this.setState({ isOpenModal: true, localizacoes: resposta.localizacoes, isAdvogado: true });*/
      } catch (err) {
        console.log(err)
        this.setState({ openAlert: true, tipoAlerta: "error", mensagemAlerta: "Senha ou usuário inválido(s)" });
      }
    } else {
      try {
        var resposta = await logarServidor(this.state.usuario, this.state.senha);
        
        this.setState({ isOpenModal: true, localizacoes: resposta.localizacoes });
      } catch (err) {
        console.log(err)
        this.setState({ openAlert: true, tipoAlerta: "error", mensagemAlerta: "Senha ou usuário inválido(s)" });
      }
    }
    return retorno;
  }

  render() {
    return (
      <Container style={{ width: 400 }}>
        <Card style={{ marginTop: 100, height: 300 }}>
          <CardContent style={{ textAlign: "center" }} >
           
            <form onSubmit={this.logar.bind(this)}>
              <TextField
                id="usuario"
                required
                label="Usuário"
                placeholder="CPF (apenas números)"
                fullWidth
                type="text"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => { this.setState({ usuario: e.target.value }) }}
              />

              <TextField
                required
                id="senha"
                label="Senha"
                placeholder="Senha"
                fullWidth
                type="password"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => this.setState({ senha: e.target.value })}
              />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => this.setState({ isServidor: e.target.checked })}
                      value={this.state.isServidor}
                      checked={true}
                      disabled={true}
                      color="primary"
                    />
                  }
                  label="Servidor"
                />
              </div>
              <Button id="idSubmetLogin" type="submit" fullWidth style={{ marginTop: 20, textAlign: "center" }} variant="contained" color="primary"
              >Entrar</Button>
            </form>
          </CardContent>
        </Card>
        <SimpleDialog loading={this.state.loading} open={this.state.isOpenModal && process.env.NODE_ENV !== 'test'} localizacoes={this.state.localizacoes} isAdvogado={this.state.isAdvogado} />

        <MySnackbarContentWrapper
          open={this.state.openAlert}
          onClose={this.handleClose}
          variant={this.state.tipoAlerta}
          message={this.state.mensagemAlerta}
        />
      </Container>
    )
  }
}

export default Login;