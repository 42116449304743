import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { AppBar, Toolbar, IconButton, Icon, Grid, LinearProgress } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Route, Switch, Redirect, Link } from "react-router-dom";
import routes from '../../../routes/servidor/components/default'
import { getUsuarioAdvogado, isAuthenticated, LOGIN_URL, logout } from '../../../services/AuthService';
import ComboSessao from '../../../components/servidor/home/comboSessao';
import ComboOrgaoJudicante from '../../../components/servidor/home/comboOrgaoJudicante';
import { AppContextProvider, AppContextConsumer } from '../../../components/servidor/shared/context';

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });
  
  let advogado = getUsuarioAdvogado();

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });

  };

  const menu = [
    { "nome": "Processos em andamento", "path": "/servidor/painel", "target": "_blank" },
    { "nome": "Processos finalizados", "path": "/servidor/painelFinalizados", "target": "_blank" }]

  const sideList = side => (
    <div
      style={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        {menu.map((item, index) => (
          <ListItem key={index} component={props => <Link to={item.path} target={item.target} {...props} />}>
            <ListItemText primary={item.nome} />
          </ListItem>
        ))}
        {advogado ? <ListItem key="10" component={props => <Link to='/advogado' target='Meus processos' {...props} />} >
          <ListItemText primary="Meus processos" />
        </ListItem> : null}
      </List>
    </div>
  );

  return (
    <div>
      <AppContextProvider>
        <AppBar
          style={{backgroundColor: "#01579b", height:60}}
          position="fixed"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer('left', true)}
              edge="start"
            >
              <MenuIcon />
            </IconButton> <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" >
                  <Grid item>
                  </Grid>
                  <Grid item>
                    <ComboOrgaoJudicante />
                  </Grid>
                  <Grid item>
                    <ComboSessao />
                  </Grid>
                  <Grid item >
                    <Link style={{ color: "white" }} to="/login" onClick={() => logout()}><Icon>exit_to_app</Icon></Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>

        </AppBar>
        <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
          {sideList('left')}
        </Drawer>
        <main style={{ marginTop: '64px', padding: "10px" }}>
          <AppContextConsumer>
            {context => (context.isLoading && <LinearProgress />)}
          </AppContextConsumer>
          <Switch>
            {routes.map((prop, key) => {
              if (!isAuthenticated() && prop.path !== LOGIN_URL) {
                return <Redirect from={prop.path} to={LOGIN_URL} key={key} />;
              } else if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route exact path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </main>
      </AppContextProvider>
    </div>
  );
}