/**
 * Enum de configurações da aplicação.
 * 
 * @readonly
 * @constant
 * @enum {Object.<string: mixed>}}
 * @copyright Tribunal Superior do Trabalho 2022
 * @author Eduardo Carvalho <eduardo.carvalho@tst.jus.br>
 */
export const CONFIGURACAO_APP = {
    PREFIXO_NOME_STORAGE: 'POTIUS-'
};

/**
 * Enum de configurações de negócio.
 * 
 * @readonly
 * @constant
 * @enum {Object.<string: mixed>}}
 * @copyright Tribunal Superior do Trabalho 2022
 * @author Eduardo Carvalho <eduardo.carvalho@tst.jus.br>
 */
 export const CONFIGURACAO_NEGOCIO = {
    LISTAR_SESSOES_PASSADAS : false,
    LISTAR_SESSOES_DO_DIA   : true,
    LISTAR_SESSOES_FUTURAS  : false
};