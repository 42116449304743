import StorageUtil from "../util/StorageUtil";

/**
 * Enum de storages da aplicação.
 * 
 * @readonly
 * @constant
 * @enum {Object.<string: string}}
 * @copyright Tribunal Superior do Trabalho 2022
 * @author Eduardo Carvalho <eduardo.carvalho@tst.jus.br>
 */
export const STORAGES = {
    ORGAOS_COLEGIADOS                 : StorageUtil.PREFIXO_NOME_STORAGE + 'orgaos-judicantes-colegiados',
    USUARIO                           : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-interno',
    USUARIO_LOCALIZACOES              : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-localizacoes',
    USUARIO_LOCALIZACAO               : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-localizacao-selecionada',
    USUARIO_ADVOGADO                  : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-advogado',
    USUARIO_TOKEN_KEY                 : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-token',
    USUARIO_TOKEN_KEY_BASE64          : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-token64',
    USUARIO_OJCS_PERMITIDOS           : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-ojcs-acesso-autorizado',
    USUARIO_OJC_SELECIONADO           : StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-ojc-selecionado',
    USUARIO_ADVOGADO_OJCS_SELECIONADOS: StorageUtil.PREFIXO_NOME_STORAGE + 'usuario-advogado-ojcs-selecionados',
    SESSAO_ID                         : StorageUtil.PREFIXO_NOME_STORAGE + 'sessao-selecionada-id',
    SESSAO_DADOS_BASICOS              : StorageUtil.PREFIXO_NOME_STORAGE + 'sessao-selecionada',
    SESSAO_COMPLETA                   : StorageUtil.PREFIXO_NOME_STORAGE + 'sessao-selecionada-completa',
};