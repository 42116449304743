import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Fab,
  LinearProgress,
  Modal,
  Backdrop,
  Fade,
  Grid,
  TextField,
  Button,
  CardActions
} from '@material-ui/core/';
import { Add } from '@material-ui/icons';

import { AppContextConsumer } from '../../advogado/shared/context';
import ProcessoApregoado from './processoApregoado';
import { getAdvogadoComOab, getProcessosAdvogadoFazParte, getProcessoDetalhe, salvarPedidoSustentacao, excluirPedidoSustentacao, getSessaoFutura, getProcesso } from '../../../services/Service'
import { getUsuarioAdvogado, agendarRenovacaoToken } from '../../../services/AuthService';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert2';
import './index.css'


const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#C7A000',
    color: 'gray'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


const MinhaSessao = (props) => {

  //let advogado = getUsuarioAdvogado();
  let numeroOab = getUsuarioAdvogado().regOAB.numero;

  let nomeAdvogado = 'Nome Teste';
  let generoAdvogado = 'M';

  let siglaOab = 'SP';
  //let siglaOabPje = 'OAB-SP';

  const classes = useStyles();
  //const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [processos, setProcessos] = useState();
  const [showPesquisaProcesso, setShowPesquisaProcesso] = useState(props.showPesquisaProcesso);
  const [processoPesquisado, setProcessoPesquisado] = useState();
  const [parteSelecionada, setParteSelecionada] = useState();
  const [partes, setPartes] = useState(props.parteSelecionada);
  const [numeroProcesso, setNumeroProcesso] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [codigo, setCodigo] = useState(props.codigo);



  const [numero, setNumero] = useState();
  const [digito, setDigito] = useState();
  const [ano, setAno] = useState();
  const [orgao, setOrgao] = useState();
  const [trt, setTrt] = useState();
  const [vara, setVara] = useState();


  const handleOpen = () => {
    setOpen(true);
    setParteSelecionada(true);
    setProcessoPesquisado(true);
    setShowPesquisaProcesso(true);
  };

  const handleClose = () => {
    setParteSelecionada(false);
    setProcessoPesquisado(false);
    setShowPesquisaProcesso(false);
    setOpen(false);
  };

  useEffect(() => {
    setarProcessos();
  }, []);

  const setarProcessos = async () => {
    getProcessosAdvogadoFazParte(numeroOab, siglaOab).then((processos) => {
      setarNoProcessoSeAdvogadoTemSustentacaoOral(processos);
      processos.forEach(async function (processo) {
        let processoDetalhe = await getProcessoDetalhe(processo.sessaoId, processo.processo.numeroCompleto);
        console.log(processoDetalhe);
      });
      setProcessos(processos, []);
    }).catch(error => {
      console.error(error);
      alert("Erro ao consultar processos dos quais o advogado faz parte.");
    });
  }

  const setarNoProcessoSeAdvogadoTemSustentacaoOral = (processos) => {
    processos.forEach(function (processo) {
      processo.advogadoTemSustentacaoOral = false;
      if (processo.pedidoSustentacaoOral)
        if (processo.pedidoSustentacaoOral.pedidosDetalhados)
          processo.pedidoSustentacaoOral.pedidosDetalhados.forEach(function (pedidoDetalhado) {
            if (pedidoDetalhado.advogados)
              pedidoDetalhado.advogados.forEach(function (advogado) {
                if (advogado.regOAB.numero === numeroOab && advogado.regOAB.siglaUf === siglaOab) {
                  processo.advogadoTemSustentacaoOral = true;
                }
              });
          });
    });
  }

  const montarPedido = (codigoParte, numeroOab, siglaOab, nomeAdvogado, generoAdvogado) => {
    return {
      "codigoDaParte": codigoParte,
      "advogado": {
        "nome": nomeAdvogado,
        "genero": generoAdvogado,
        "regOAB": {
          "numero": numeroOab,
          "siglaUf": siglaOab
        },
        "preferencia": false,
        "sustentacaoOral": true,
        "presente": "NA"
      }
    };
  }

  const definePartes = (partesProcessuais) => {
    let partesTmp = [];
    partesProcessuais.forEach((e) => {
      return e.partes.forEach(element => {
        partesTmp.push(element.parte);
      });
    })
    setPartes(partesTmp);
  }

  const incluirPedido = async () => {
    getSessaoFutura(numeroProcesso).then((sessao) => {
      setIsLoading(true);

      let parte = JSON.parse(parteSelecionada);
      let pedido = montarPedido(parte.codigo, numeroOab, siglaOab, nomeAdvogado, generoAdvogado);



      (pedido, sessao, numeroProcesso).then((codigo) => {
        swal.fire({
          title: `<p style='color: "green"'>Sucesso</p>`,
          html: `<p style='color: "#C7A000"'>${numeroProcesso}</p><p style='color: "#C7A000"'>Parte: ${parte.nome}</p style='color: "#C7A000"'><p>Código de confirmação: ${codigo}</p>`,
          type: "success",
          confirmButtonText: "OK"
        });
        handleClose();
        setCodigo(codigo);
        setarProcessos();
      }).catch(error => {
        alert("Erro ao realizar pedido.")
        console.error(error);
      });

    }).catch(error => {
      alert("Erro ao realizar pedido.");
      console.error(error);
    }).finally(function () {
      setIsLoading(false);
    });
  }

  const pesquisarProcesso = async (event) => {

    setIsLoading(true);
    event.preventDefault();

    const processoNumero = numero + "-" + digito + "." + ano + "." + orgao + "." + trt + "." + vara;

    let sessao;
    try {

      sessao = await getSessaoFutura(processoNumero);

      let processo = await getProcessoDetalhe(sessao, processoNumero);

      //TODO retirar a primeira requisição.
      let processoSemDetalhes = await getProcesso(sessao, processoNumero);

      setNumeroProcesso(processoSemDetalhes.data.processo.numeroCompleto);
      if (processo) {
        setProcessoPesquisado(processo.data);
        definePartes(processo.data.partesProcessuais);
        setShowPesquisaProcesso(false);
      } else {
        alert('Processo não encontrado nessa sessão: ' + sessao);
      }


    } catch (error) {
      alert('Processo não encontrado na sessão: ' + sessao);
    } finally {
      setIsLoading(false);
    }

  }

  const adicionarSustentacaoOral = async (row) => {
    try {

      setIsLoading(true);

      let processoDetalhe = await getProcessoDetalhe(row.sessaoId, row.processo.numeroCompleto);
      let advogado = await getAdvogadoComOab(numeroOab, siglaOab, row.sessaoId);

      let codigoParte = null;
      processoDetalhe.data.partesProcessuais.forEach(function (parteProcessual) {
        parteProcessual.partes.forEach(function (parte) {
          if (parte.representantes) {
            parte.representantes.forEach(function (representante) {
              console.log('parte');
              console.log(parte);
              if (representante.codigo === advogado.numRepresentante) {
                codigoParte = parte.parte.codigo
              }
            });
          }
        });
      });

      let pedido = montarPedido(codigoParte, numeroOab, siglaOab, nomeAdvogado, generoAdvogado);
      console.log(pedido);

      await salvarPedidoSustentacao(pedido, row.sessaoId, row.processo.numeroCompleto);
      setarProcessos();
    } catch (e) {
      alert('Não foi possível adicionar o pedido de sustentação oral do processo ' + row.processo.numeroCompleto);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const removerSustentacaoOral = async (row) => {
    try {
      setIsLoading(true);
      await excluirPedidoSustentacao(row.sessaoId, row.processo.numeroCompleto, numeroOab + '-' + siglaOab);
      setarProcessos();
    } catch (e) {
      alert('Não foi possível excluir o pedido de sustentação oral no processo ' + row.processo.numeroCompleto);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div >
      { /*<SelecaoOrgao />*/}

      <div>
        <Grid container justifyContent="center" style={{ color: "white", backgroundColor: "#1976d2", alignItems: "center" }}>
          <Grid item style={{ padding: 5 }} >
            <Typography variant={"h6"} style={{ color: "white" }}>
              ÚLTIMOS PROCESSOS APRECIADOS
            </Typography>
          </Grid>
        </Grid>

        <Container style={{ width: 1000, marginTop: 50 }}>
          <Card  >
            <CardContent style={{ textAlign: "center" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"><Typography style={{ fontWeight: "bold" }}>PROCESSO</Typography></TableCell>
                    <TableCell align="left"><Typography style={{ fontWeight: "bold" }}>SESSÃO</Typography></TableCell>
                    <TableCell align="left"><Typography style={{ fontWeight: "bold" }}>SUSTENTAÇÃO ORAL</Typography></TableCell>
                    <TableCell align="left"><Typography style={{ fontWeight: "bold" }}></Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processos && processos.map((row, index) => {
                    return (
                      <TableRow key={row ? row.processo.numeroCompleto : ''}>
                        <TableCell id={"idNumeroCompleto" + index} align="left">
                          <Typography>
                            {row.processo.numeroCompleto}
                          </Typography>
                        </TableCell>
                        <TableCell id={"idNumeroCompleto" + index} align="left">
                          <Typography>
                            {row.sessaoId}
                          </Typography>
                        </TableCell>
                        <TableCell id={"idNumeroCompleto" + index} align="left">
                          <Typography>
                            {row.advogadoTemSustentacaoOral ? 'Sim' : 'Não'}
                          </Typography>
                        </TableCell>
                        <TableCell id={"idNumeroCompleto" + index} align="left">

                          {row.advogadoTemSustentacaoOral ?
                            <Typography>
                              <Button onClick={removerSustentacaoOral.bind(this, row)}>Remover</Button>
                            </Typography> :
                            <Typography>
                              <Button onClick={adicionarSustentacaoOral.bind(this, row)}>Adicionar</Button>
                            </Typography>}


                        </TableCell>
                      </TableRow>)
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Container>
      </div>

      <Fab className={classes.fab} aria-label="Adicionar" onClick={handleOpen}>
        <Add />
      </Fab>
      <AppContextConsumer>
        {ctx => (
          ctx.processoApregoado != null &&
          <div>
            <ProcessoApregoado processoApregoado={ctx.processoApregoado} />
          </div>
        )}
      </AppContextConsumer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card style={{ width: "90%" }}>
            <form onSubmit={(e) => pesquisarProcesso(e)} >
              <CardHeader
                disableTypography={true}
                title={<Typography variant={"h6"} >INCLUIR SUSTENTAÇÃO ORAL</Typography>}
                subheader={showPesquisaProcesso ? <Typography >Encontre o processo</Typography> : <Typography >Escolha da parte</Typography>} />
              <CardContent >
                {isLoading && <LinearProgress />}
                {showPesquisaProcesso &&
                  <Grid container spacing={1} justifyContent="center" className="formControl" style={{ overflowY: "scroll", WebkitOverflowScrolling: "none", height: 300 }}>
                    <Grid item sm={6} md={6} >
                      <TextField
                        id="numero"
                        label="Número"
                        required={true}
                        type="number"
                        margin="normal"
                        fullWidth
                        onChange={(e) => { setNumero(e.target.value) }}
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                        }}
                      />
                    </Grid>

                    <Grid item sm={1} md={1}>
                      <TextField
                        id="digito"
                        required={true}
                        label="Dígito"
                        type="number"
                        margin="normal"
                        fullWidth
                        onChange={(e) => { setDigito(e.target.value) }}
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                        }}
                      />
                    </Grid>

                    <Grid item sm={2} md={2}>
                      <TextField
                        id="ano"
                        label="Ano"
                        required={true}
                        type="number"
                        margin="normal"
                        onChange={(e) => { setAno(e.target.value) }}
                        fullWidth
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                        }}
                      />
                    </Grid>
                    <Grid item sm={1} md={1}>
                      <TextField
                        id="orgao"
                        label="Órgão"
                        required={true}
                        type="number"
                        margin="normal"
                        onChange={(e) => { setOrgao(e.target.value) }}
                        fullWidth
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
                        }}
                      />
                    </Grid>
                    <Grid item sm={1} md={1}>
                      <TextField
                        id="trt"
                        type="number"
                        required={true}
                        label="Tribunal"
                        margin="normal"
                        onChange={(e) => { setTrt(e.target.value) }}
                        fullWidth
                        variant="outlined"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                        }}
                      />
                    </Grid>

                    <Grid item sm={1} md={1}>
                      <TextField
                        id="vara"
                        type="number"
                        required={true}
                        label="Vara"
                        margin="normal"
                        onChange={(e) => { setVara(e.target.value) }}
                        fullWidth
                        variant="outlined"

                      />
                    </Grid>
                  </Grid>}

                {!showPesquisaProcesso && processoPesquisado != null &&
                  <Grid container spacing={1} justifyContent="flex-start">
                    <Grid item sm={12} md={12}>
                      <Typography variant={"h6"} >{processoPesquisado.numeroCompleto}</Typography>
                    </Grid>
                    <Grid item sm={12} md={12} >
                      <select
                        required
                        className="select"
                        value={parteSelecionada}
                        onChange={async (e) => {
                          setParteSelecionada(e.target.value);
                        }}
                      >
                        <option style={{ color: "#01426A" }} key={1} value={""}>Seleciona a parte</option>
                        {partes != null && partes.map(parte => (
                          <option style={{ color: "#01426A" }} key={parte.codigo} value={JSON.stringify(parte)}>{parte.nome}</option>
                        ))
                        })}
                      </select>
                    </Grid>
                  </Grid>}
              </CardContent>
              {showPesquisaProcesso && <CardActions>
                <Grid container justifyContent="flex-end">
                  <Button id="idCancelarPesquisa" type="submit" variant="contained" onClick={handleClose} color="secondary">Cancelar</Button>
                  <Button id="idPesquisar" style={{ marginLeft: 4 }} type="submit" variant="contained" color="primary">Pesquisar</Button>
                </Grid>
              </CardActions>}

            </form>
            {!showPesquisaProcesso && <CardActions>
              <Grid container justifyContent="flex-end">
                <Button id="idSubmitCancelar" type="submit" variant="contained" onClick={handleClose} color="secondary">Cancelar</Button>
                <Button id="idSubmitPedido" data-testid="incluirPedido" style={{ marginLeft: 4 }} onClick={incluirPedido} type="submit" variant="contained" color="primary">Incluir</Button>
              </Grid>
            </CardActions>}
          </Card>
        </Fade>
      </Modal>
      <div style={{ opacity: "0.0" }}>{codigo}</div>
    </div>
  )

}

export default MinhaSessao;