import React, { useState } from 'react';
import {
    Typography,
    Card,
    CardContent,
    IconButton, Collapse, CardHeader
} from '@material-ui/core/';
import SustentacaoOral from '../../servidor/painel/sustentacaoOral';
import { ExpandMore, CastConnected } from '@material-ui/icons';


const ProcessoApregoado = (props) => {
    //const [processoApregoado, setProcessoApregoado] = useState(props.processoApregoado);
    const [processoApregoado] = useState(props.processoApregoado);

    const [expanded, setExpanded] = useState(props.expanded);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    return (
        <Card style={{ backgroundColor: "#C7A000", marginTop: 20 }} >
            <CardHeader
                avatar={
                    <CastConnected />
                }
                action={
                    <IconButton
                        //aria-label="settings"
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more">
                        <ExpandMore />
                    </IconButton>
                }
                disableTypography={true}
                title={<Typography onClick={handleExpandClick} variant={"h6"}>{processoApregoado != null && processoApregoado.processo != null ? processoApregoado.processo.numeroCompleto : ""}</Typography>}
                subheader={<Typography ><span style={{ fontWeight: "bold" }}>Relator(a):</span> {processoApregoado != null && processoApregoado.processo != null ? processoApregoado.processo.relator.nome : ""}</Typography>}
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {processoApregoado.pedidoSustentacaoOral != null && processoApregoado.pedidoSustentacaoOral.pedidosDetalhados != null
                        ? <SustentacaoOral indexPrincipal={-1} pedidosDetalhados={processoApregoado.pedidoSustentacaoOral.pedidosDetalhados} />
                        : <Typography> Nenhuma preferência ou pedido de sustentação oral.</Typography>}
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default ProcessoApregoado;