import React, { useState, useEffect } from 'react';
import './index.css'
import AppBarCustom from '../../components/servidor/painel/appBarCustoms';
import PainelSessaoConteudo from '../../components/servidor/painel';
import { Toolbar, LinearProgress } from '@material-ui/core';

import { sortBySequenciaPreferencia, sortByOrdemPregao, removerProcessoApregoado, processosPendentesDeJulgamento, getProcessosRelator } from '../../util/processo';
import routes from '../../routes/servidor/components/painel';
import { isAuthenticated, LOGIN_URL } from '../../services/AuthService';
import { Route, Switch, Redirect } from "react-router-dom";
import PainelFinalizadosConteudo from '../../components/servidor/painel/finalizados';
import { getProcessoApregoado, getPlanilha, getSessaoDetalhada } from '../../services/Service';
require('../../services/EnvelopeMultiplosCanais');
const { getUrlApi } = require('../../util/api');


const Painel = () => {

  const [processoApregoado, setProcessoApregoado] = useState();
  const [processos, setProcessos] = useState();
  const [dataSessao, setDataSessao] = useState();
  const [sessao, setSessao] = useState();
  const [orgao, setOrgao] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [detalhePlanilha, setDetalhePlanilha] = useState();
  const [showPlanilha, setShowPlanilha] = useState();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);

      let orgaoLocal = JSON.parse(localStorage.getItem("orgao"));
      let sessaoLocal = JSON.parse(localStorage.getItem("sessao"));
      setOrgao(orgaoLocal);
      setSessao(sessaoLocal);

      if (orgao && orgao.sigla && sessaoLocal && sessaoLocal.id) {
        getProcessoApregoado(orgao.sigla, sessaoLocal.id).then((resp) => {
          setProcessoApregoado(resp);
          atualizarPainel(sessaoLocal, resp, orgao);
        }).catch(error => {
          atualizarPainel(sessaoLocal, null, orgao);
          console.error(error);
        });

        iniciarEnvelopeProcessoApregoado(orgao, sessaoLocal);
      }
    };

    init();
  }, []);

  const formataData = (valor) => {
    if (valor !== null) {
      return valor.slice(0, 10);
    } else {
      return '';
    }
  }

  const iniciarEnvelopeProcessoApregoado = async (orgao, sessao) => {
    let settings = {
      url: await getUrlApi('REACT_APP_ENVELOPE') + '/cometd'
    };

    if (sessao) {
      let clienteEnvelopeProcessoApregoado = window.envelope.novoClienteMultiplosCanais(settings);
      clienteEnvelopeProcessoApregoado.iniciar(function () {
        let sessoes = sessao.id.split('----');

        for (let s of sessoes) {
          let canalPregao = `/sessao/${s}/pregao`;
          if (!clienteEnvelopeProcessoApregoado.canalEstaRegistrado(canalPregao)) {
            clienteEnvelopeProcessoApregoado.registrarCanal(canalPregao, (mensagem) => {
              let data = JSON.parse(mensagem.data);
              console.log('Processo apregoado: ' + data.apregoado[0]);
              tratarEventoEnvelope(mensagem, orgao, sessao, setProcessoApregoado, atualizarPainel);
            });
          }
        }
      });

      let clienteEnvelopePresencaAdvogado = window.envelope.novoClienteMultiplosCanais(settings);
      clienteEnvelopePresencaAdvogado.iniciar(function () {
        let canalPregao = `/sessao/${sessao.id}/processo`;
        console.log('canalPregao', canalPregao)
        if (!clienteEnvelopePresencaAdvogado.canalEstaRegistrado(canalPregao)) {
          clienteEnvelopePresencaAdvogado.registrarCanal(canalPregao, (mensagem) => {
            tratarEventoEnvelope(mensagem, orgao, sessao, setProcessoApregoado, atualizarPainel);
          });
        }
      });
    }
  }

  const atualizarPainel = async (sessao, processoApregoado, orgao) => {

    //setDetalhePlanilha(null);
    //setProcessoApregoado(null);
    //setShowPlanilha(false);

    try {
      if (sessao && sessao.id) {
        //iniciar loading
        setIsLoading(true)
        let detalhe = await getSessaoDetalhada(sessao.id);
        let processosOrdenados = sortBySequenciaPreferencia(sortByOrdemPregao(detalhe.processos));
        if (processoApregoado) {
          setProcessoApregoado(processoApregoado);
          setProcessos(removerProcessoApregoado(processosOrdenados, processoApregoado.processo.numeroCompleto));
        } else {
          setProcessos(processosOrdenados);
        }
        setDataSessao(formataData(sessao.prazos.dataInicioSessaoPresencial));
        let planilhaResp = await getPlanilha(orgao.sigla);

        if (planilhaResp.planilha) {
          setProcessoApregoado({ processo: { numeroCompleto: 'Planilha de processos do relator ' + planilhaResp.relator.sigla, relator: { nome: planilhaResp.relator.sigla } } })
          let processosRelator = getProcessosRelator(processosPendentesDeJulgamento(processosOrdenados), planilhaResp.relator.sigla);
          let delalhePlanilha = {
            relator: processosRelator[0].processo.relator && processosRelator[0].processo.relator.nome ? processosRelator[0].processo.relator.nome : planilhaResp.relator.sigla,
            primeiro: processosRelator[0].processo.numeroCompleto,
            ultimo: processosRelator[processosRelator.length - 1].processo.numeroCompleto,
            quantidade: processosRelator.length
          }
          setDetalhePlanilha(delalhePlanilha);
          setShowPlanilha(true);

        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false)
      console.error(error);
    }
  }

  return (
    <div>
      {sessao && <AppBarCustom
        style={{ backgroundColor: "#01579b", height: 60 }}
        orgaoJudicante={sessao.orgaoJudicante ? sessao.orgaoJudicante.descricao : ''}
        processo={processoApregoado ? processoApregoado.processo.numeroCompleto : ''}
        sessao={sessao.descricao ? sessao.descricao : ''}
        tipo={sessao.tipo ? sessao.tipo
          : ''}
        dataSessao={dataSessao}
      />}
      <Toolbar />
      {isLoading && <LinearProgress />}
      {sessao && sessao.id && processos &&
        <Switch>
          {routes.map((prop, key) => {
            if (!isAuthenticated() && prop.path !== LOGIN_URL) {
              return <Redirect from={prop.path} to={LOGIN_URL} key={key} />;
            } else if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            } else if (prop.path === "/servidor/painel") {
              return <Route exact path={prop.path} component={() => <PainelSessaoConteudo processoApregoado={processoApregoado} sessao={sessao} processos={processos} detalhePlanilha={detalhePlanilha} showPlanilha={showPlanilha} />} key={key} />;
            } else {
              return <Route exact path={prop.path} component={() => <PainelFinalizadosConteudo processoApregoado={processoApregoado} sessao={sessao} processos={processos} />} key={key} />;
            }
          })}
        </Switch>}
    </div>
  );
}

export default Painel;

function tratarEventoEnvelope(mensagem, orgao, sessao, setProcessoApregoado, atualizarPainel) {
  let data = JSON.parse(mensagem.data);
  console.log('data', data);
  getProcessoApregoado(orgao.sigla, sessao.id).then((resp) => {
    setProcessoApregoado(resp);
    atualizarPainel(sessao, resp, orgao);
  }).catch(error => {
    atualizarPainel(sessao, null, orgao);
    console.error(error);
  });
}
