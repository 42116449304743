import React, { useState, useContext, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';

import { AppContext } from '../shared/context';
import './selecao.css';
import { STORAGES } from '../../../app/Storages';

const ComboOrgaoJudicante = () => {
  const context = useContext(AppContext);
  const [orgaos] = useState(JSON.parse(localStorage.getItem(STORAGES.ORGAOS_COLEGIADOS)));
  const [orgaoSelecionado, setOrgaoSelecionado] = useState('selecione');

  useEffect(() => {
    if (orgaos.length === 1) {
      context.defineOrgao(orgaos[0]);
      setOrgaoSelecionado(orgaos[0]);
      localStorage.setItem('orgao', JSON.stringify(orgaos[0]));
    }
  }, []);

  return (
    <Select
      disabled={orgaos.length < 2}
      className={'selecao'}
      variant="outlined"
      value={orgaoSelecionado}
      onChange={async (e) => {
        context.defineOrgao(e.target.value);
        localStorage.setItem('orgao', JSON.stringify(e.target.value));
        setOrgaoSelecionado(e.target.value);
      }}
      IconComponent={() => null}
    >
      <MenuItem value="selecione">Selecione um orgão</MenuItem>
      {orgaos && orgaos.map(orgao => (
        <MenuItem key={orgao.id} value={orgao}>{orgao.sigla}</MenuItem>
      ))
      })
    </Select>
  )
}

export default ComboOrgaoJudicante;