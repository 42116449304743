import React, { createContext, useState } from "react";
import moment from 'moment';
import { getSessoes, getSessaoDetalhada} from '../../../../services/Service';


export const Context = createContext({});

export const Provider = props => {
  // Initial values are obtained from the props
  const {
    children
  } = props;

  // Use State to keep the values
  const [sessoes, setSessoes] = useState();
  const [sessao, setSessao] = useState();
  const [sessaoDetalhada, setSessaoDetalhada] = useState();
  const [orgaos, setOrgaos] = useState();
  const [orgao, setOrgao] = useState();
  const [processoApregoado, setProcessoApregoado] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPecas, setIsShowPecas] = useState(false);
  const [showHome, setShowHome] = useState(true);

  function apenasSessoesHoje(s) {
    if (s === undefined || s.prazos === undefined || s.prazos.dataInicioSessaoPresencial === undefined) {
      return false;
    }

    let sessaoDiaMesAnoHoraMinSeg = s.prazos.dataInicioSessaoPresencial; // "12-05-2017-09-00-00"
    let hojeDiaMesAnoHoraMinSeg = moment(new Date()).format('DD-MM-YYYY-HH-mm-ss');

    if (
      !moment(sessaoDiaMesAnoHoraMinSeg, 'DD-MM-YYYY-HH-mm-ss').isBefore(moment(hojeDiaMesAnoHoraMinSeg, 'DD-MM-YYYY-HH-mm-ss'), 'day')
      &&
      !moment(sessaoDiaMesAnoHoraMinSeg, 'DD-MM-YYYY-HH-mm-ss').isAfter(moment(hojeDiaMesAnoHoraMinSeg, 'DD-MM-YYYY-HH-mm-ss'), 'day')
    ) {

      return true;
    }

    return false;
  }

  const defineOrgao = (ojc) => {
    getSessoes(ojc.sigla).then((ss) => {
      if (ss != null) {
        let listaSessoesHoje;

        // FIXME Remover após a finalização dos testes em Qualidade
        if (window.location.pathname.indexOf("prd.rede.tst") !== -1 || window.location.pathname.indexOf("potius.tst.jus.br") !== -1) {
          listaSessoesHoje = ss.filter((s) => apenasSessoesHoje(s));
        } else {
          listaSessoesHoje = ss;
        }

        setSessoes(listaSessoesHoje);
        localStorage.removeItem('sessoes');
        localStorage.setItem('sessoes', JSON.stringify(listaSessoesHoje));
      }
    })
    setOrgao(ojc);
  }

  const defineSessao = async (s) => {
    setIsLoading(true);
    let sessaoDetalhadaCarregada = await getSessaoDetalhada(s.id); // TODO Chamando o método para consultar e carregar no Storage. Melhorar isso aqui.
    setSessao(sessaoDetalhadaCarregada);
    setSessaoDetalhada(sessaoDetalhadaCarregada);
    console.log("Sessão " + s.id + " definida. [sessaoDetalhada#defineSessao]");
    setIsLoading(false);
  }

  const defineShowHome = (valor) => {
    setShowHome(valor);
  }


  // Make the context object:
  const appContext = {
    showHome, setShowHome,
    sessao, setSessao,
    sessoes, setSessoes,
    orgaos, setOrgaos,
    processoApregoado, setProcessoApregoado,
    isLoading, setIsLoading,
    isShowPecas, setIsShowPecas,
    orgao, setOrgao,
    defineOrgao,
    defineSessao,
    defineShowHome
  };

  // pass the value in provider and return
  return <Context.Provider value={appContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;