const axios = require('axios');

export async function getUrlApi(variavel) {
    let retorno = '';
    const ENV_URL = '/env.json';

    if (process.env.NODE_ENV !== 'test') {
        try {
            let resp = await axios.get(ENV_URL, { cache: false });
            retorno = resp.data[variavel];
        } catch (error) {
            console.error(error);
            //TODO throw new Error('Falha na consulta das configurações de ambiente.', { cause: error });
        }
    }

    return retorno;
}