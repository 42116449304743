import React, { useState, useEffect, useContext, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import SustentacaoOral from './sustentacaoOral';
import { getPrimeiraSessao } from '../../../util/processo';
import { AppContext } from '../../../components/servidor/shared/context';

const { getUrlApi } = require('../../../util/api');

const PainelSessaoConteudo = (props) => {

	/*constructor(props, context) {
		super(props, context);
		this.state = {
		    processos: null,
		    sessao: null
		  };
		
	}
	*/
	const [processos, setProcessos] = useState(props.processos.filter((e) => { return e.resultadoJulgamento.descricao == "Não julgado" }));
	const [sessao, setSessao] = useState(props.sessao);
	const [processoApregoado, setProcessoApregoado] = useState(props.processoApregoado);
	const [detalhePlanilha, setDetalhePlanilha] = useState(props.detalhePlanilha);
	const [showPlanilha, setShowPlanilha] = useState(props.showPlanilha);
	
	const context = useContext(AppContext);
	
	useEffect(() => {
		iniciarEnvelope();
    }, []);
	
 /* componentDidMount() {
    this.setState({
      processos: this.props.processos.filter((e) => { return e.resultadoJulgamento.descricao == "Não julgado" }),
      sessao: this.props.sessao,
      processoApregoado: this.props.processoApregoado,
      detalhePlanilha: this.props.detalhePlanilha,
      showPlanilha: this.props.showPlanilha,
    });
    
  }*/
  
  const iniciarEnvelope = async () => {
      var settings = {
          url: await getUrlApi('REACT_APP_ENVELOPE') + '/cometd'
      };
      if (sessao && sessao.id) {
          let clienteEnvelopeProcessoApregoado = window.envelope.novoClienteMultiplosCanais(settings);
        
          clienteEnvelopeProcessoApregoado.iniciar(function () {
        	  
              let canalListaAndamento = '/sessao/' + sessao.id + '/ordenacao-processos';
              if (!clienteEnvelopeProcessoApregoado.canalEstaRegistrado(canalListaAndamento)) {
                  console.log('canal lista andamento', canalListaAndamento)
                  clienteEnvelopeProcessoApregoado.registrarCanal(canalListaAndamento, (mensagem) => {
                      console.log('envelope', mensagem);
                      tratarAtualizacaoOrdemProcessos(mensagem);
                  });
              };
          });

      }
  }
  
  const tratarAtualizacaoOrdemProcessos = async (mensagem) => {
	  let novaLista = [];
	  
	  let mensagemLista = mensagem.data.substring(1, mensagem.data.length - 1).split(',').map(function(item) {
		  	return item.substring(1, item.length - 1);
  		});
	  
	  mensagemLista.forEach(function(mensagemItem) {
		  processos.forEach(function(processo) {
			  if (processo.processo.numeroCompleto.includes(mensagemItem) 
					  && processoApregoado.processo.numeroCompleto != processo.processo.numeroCompleto) {
				  novaLista.push(processo);
			  }
		  });
	  });
	  
	  setProcessos(novaLista);
  }
  
  /*render() {*/
    return (
      <div>
        {processos && !showPlanilha &&

          <Grid container >
            {processoApregoado &&
            <Grid item xs={4} style={{ padding: 10 }}>
              <Card style={{ backgroundColor: "#D5F5E3", height: 400 }}>
                  <CardContent>
                    <Typography className="label" variant={"h6"}>
                      PROCESSO
                    </Typography>
                    <Typography variant={"h6"} id={"idNumeroCompleto-1"} style={{ color: "black" }}>
                      {processoApregoado.processo.numeroCompleto}
                    </Typography>

                    <Typography className="label" variant={"h6"}>
                      RELATOR
                </Typography>
                    <Typography variant={"h6"} id={"idRelator-1"} style={{ color: "black" }} >
                      {processoApregoado.processo.relator.nome != null ? processoApregoado.processo.relator.nome.toUpperCase() : ""}
                    </Typography>
                    <p></p>
                    <Typography className="label" variant={"h6"} style={{marginBottom: 10}}>
                      INSCRIÇÕES DE ADVOGADO
                    </Typography>
                    {processoApregoado.pedidoSustentacaoOral && processoApregoado.pedidoSustentacaoOral.pedidosDetalhados ?
                      <SustentacaoOral indexPrincipal={"-1"} pedidosDetalhados={processoApregoado.pedidoSustentacaoOral.pedidosDetalhados} />
                    	: <Typography>Nenhuma inscrição de advogado.</Typography>}
                  </CardContent>
              </Card>
            </Grid>}

            {processos && processos.map((row, index) => (
              <Grid key={index} item xs={4} style={{ padding: 10 }}>
                {processos && processos.length > 0 &&
                  <Card style={{ height: 400 }}>
                    {processos[index] &&
                      <CardContent>
                        <div className="wrapper">
                          <span className="badge" style={{ background: "#EEEEEE" }} >{index + 1}</span>
                        </div>
                        <Typography className="label" variant={"h6"}>
                          PROCESSO
                    </Typography>
                        <Typography variant={"h6"} id={"idNumeroCompleto" + index} style={{ color: "black" }}  >
                          {processos[index].processo.numeroCompleto}
                        </Typography>

                        <Typography className="label" variant={"h6"}>
                          RELATOR
                    </Typography>

                        <Typography variant={"h6"} id={"idRelator" + index} style={{ color: "black" }}>
                          {processos[index].processo.relator.nome != null ? processos[index].processo.relator.nome.toUpperCase() : ""}
                        </Typography>
                        <p></p>
                          <span>
                            <Typography className="label" variant={"h6"} style={{marginBottom: 10}}>
                              PREFERÊNCIAS DE ADVOGADO
                            </Typography>
                              {processos[index].pedidoSustentacaoOral && processos[index].pedidoSustentacaoOral.pedidosDetalhados ?
                            <SustentacaoOral indexPrincipal={index} pedidosDetalhados={processos[index].pedidoSustentacaoOral.pedidosDetalhados} />
                              : <Typography>Nenhuma preferência ou pedido de sustentação oral.</Typography>}
                           </span>
                      </CardContent>}
                  </Card>}
              </Grid>))}
          </Grid>}
        {showPlanilha &&
          <Grid container >
            <Grid item xs={4} />
            <Grid item xs={4} style={{ padding: 10 }}>
              <Card style={{ height: 200 }}>
                {detalhePlanilha && detalhePlanilha.relator &&
                  <CardHeader disableTypography={true} title={
                    <div>
                      <Typography variant={"h6"}>Planilha de processos do relator</Typography>
                      <Typography variant={"h6"} >{detalhePlanilha.relator}</Typography>
                    </div>}
                  />}

                <CardContent>
                  {detalhePlanilha && detalhePlanilha.primeiro != null && <Typography>Primeiro: {detalhePlanilha.primeiro}</Typography>}
                  {detalhePlanilha && detalhePlanilha.ultimo != null && <Typography>Último: {detalhePlanilha.ultimo}</Typography>}
                  {detalhePlanilha && detalhePlanilha.quantidade != null && <Typography>Pendentes: {detalhePlanilha.quantidade}</Typography>}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        }

        {!processoApregoado && processos && processos.length === 0 && 
          <Grid container >
            <Grid item xs={4} />
            <Grid item xs={4} style={{ padding: 10 }}>
              <Card style={{ height: 200 }}>
                <CardContent>
                  <Typography variant={"h6"} >Não há processos com advogados presentes.</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        }

      </div>
    )
}
        
export default PainelSessaoConteudo;