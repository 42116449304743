import React from 'react';
import Processo from '../processo';

class Home extends React.Component {
  
 render() {
    return (
      <div>
        <Processo />
      </div>
    )
  }
}

export default Home;